$forMobile: "true";@import 'Application/view/generated/colors';

.c24m-footer {
    // The footer is not displayed initially but immediately after the wireframe script is initialized.
    display: none;

    &--visible {
        // since we know it's a block-element by default
        display: block;
    }
}
