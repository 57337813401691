$forMobile: "true";@import 'Application/view/framework/common/settings';

.c24hContentWrapper {
    padding: 0 10px;

    &--unifiedPadding {
        padding: 0 $global-spacing-small;
    }
}

.clearfix {
    @include clearfix;
}

a {
    outline: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}
