@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mobileSettings';

$body-font-family: mobileSettings(bodyFontFamily);
$body-font-color: mobileSettings(bodyFontColor);
$header-font-family: mobileSettings(bodyFontFamily);
$input-color: mobileSettings(inputColor);
$input-placeholder-color: mobileSettings(inputPlaceholderColor);
$input-font-size: mobileSettings(inputFontSize);
$input-border: mobileSettings(inputBorder);
$form-label-font-size: mobileSettings(formLabelFontSize);
$form-label-color: mobileSettings(formLabelColor);
$input-padding-vertical: mobileSettings(inputPaddingVertical); // custom variable to use inside input-extensions for height calculation
$input-padding: $input-padding-vertical 14px; // override forms/_text.scss variable
$callout-font-color: mobileSettings(calloutFontColor);
$button-sizes: mobileSettings(buttonSizes);
$button-padding: mobileSettings(buttonPadding);
$button-background-hover-lightness: mobileSettings(buttonBackgroundHoverLightness);
$foundation-palette: mobileSettings(foundationPalette);
$accordion-content-color: $body-font-color;
