$forMobile: "true";@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.customerSupportBoxMobile {
    &__phone,
    &__email {
        color: $blueFire;
        font-size: 20px;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 0;
    }

    &__phoneInfo,
    &__emailInfo,
    &__serviceCodeContainer {
        font-size: 16px;
        line-height: 1.5;
    }

    &__emailInfo {
        margin: 0;
    }

    &__divider {
        margin: 15px 0;
    }
}
