$forMobile: "true";@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/settings.mobile';
@import 'foundation-sites/scss/foundation';

$global-flexbox: true !global;

@include iconFontFace;
@include foundation-global-styles;
@include legacyIconClasses;
@include foundation-flex-grid;
@include foundation-typography-base;
@include foundation-visibility-classes;
@include foundation-flex-classes;
@include foundation-print-styles;

/* stylelint-disable no-invalid-position-at-import-rule */
@import './input-extensions';
@import './body-extensions';
/* stylelint-enable no-invalid-position-at-import-rule */

// overwrite holding css on longer pages
body {
    font-size: 12px; // thanks Holding!
}
